// TODO: Add proper types
export const state = () => ({
  validSteps: [],
  user: null,
  emails: [],
  tickets: [],
  preparedTickets: [],
  event: null,
  cancelModal: false,
  ticketFormat: null,
  note: null
});

export const mutations = {
  setEmails(state, payload) {
    state.emails = payload;
  },
  setNote(state, payload) {
    state.note = payload;
  },
  addStepValid(state, payload) {
    if (state.validSteps.includes(payload)) {
      return;
    }
    state.validSteps.push(payload);
  },
  removeStepValid(state, payload) {
    const index = state.validSteps.indexOf(payload);
    if (index > -1) {
      state.validSteps.splice(index, 1);
    }
  },
  setSteps(state, payload) {
    state.validSteps = payload;
  },
  setTickets(state, payload) {
    // check if ticket is already in the list
    const index = state.tickets.findIndex(
      (ticket) => ticket.ticketConfigId === payload.ticketConfigId
    );
    if (index > -1) {
      state.tickets[index] = payload;
    } else {
      state.tickets.push(payload);
    }
  },
  clearTickets(state) {
    state.tickets = [];
  },
  setEvent(state, payload) {
    state.event = payload;
  },
  setPreparedTickets(state, payload) {
    state.preparedTickets = payload;
  },
  setModal(state, payload) {
    state.cancelModal = payload;
  },
  setTicketFormat(state, payload) {
    state.ticketFormat = payload;
  }
};

export const getters = {
  getNote: (state) => {
    return state.note;
  },
  getEmails: (state) => {
    return state.emails;
  },
  getNumberOfEmails: (state) => {
    return state.emails.length;
  },
  getSteps: (state) => {
    return state.validSteps;
  },
  getTickets: (state) => {
    return state.tickets;
  },
  getEvent: (state) => {
    return state.event;
  },
  getModal: (state) => {
    return state.cancelModal;
  },
  getPreparedTickets: (state) => {
    return state.preparedTickets;
  },
  getTicketFormat: (state) => {
    return state.ticketFormat;
  }
};

export const actions: any = {
  setEvent({ commit }, payload) {
    commit('setEvent', payload);
  },
  setEmails({ commit }, payload) {
    commit('setEmails', payload);
  },
  setNote({ commit }, payload) {
    commit('setNote', payload);
  },
  clearNote({ commit }) {
    commit('setNote', null);
  },
  clearEmails({ commit }) {
    commit('setEmails', []);
    commit('setSteps', []);
  },
  addStepValid({ commit }, payload) {
    commit('addStepValid', payload);
  },
  removeStepValid({ commit }, payload) {
    commit('removeStepValid', payload);
  },
  setTickets({ commit }, payload) {
    commit('setTickets', payload);
  },
  clearTickets({ commit }) {
    commit('clearTickets');
  },
  setPreparedTickets({ commit }, payload) {
    commit('setPreparedTickets', payload);
  },
  setModal({ commit }, payload) {
    commit('setModal', payload);
  },
  setTicketFormat({ commit }, payload) {
    commit('setTicketFormat', payload);
  }
};
